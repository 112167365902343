/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import BrandIcon from 'parts/BrandIcon';
import Button from 'elements/Button';

export default function Footer() {
  return (
    <div className="bg-gray-50 border-t border-gray-200">
      <div className="container flex-col mx-auto ">
        <div className="flex flex-col sm:flex-row mt-8 justify-center">
          <div className="w-1/3 flex-col ml-16 mr-8">
            <BrandIcon />
            <p className="w-full text-lg text-gray-400 font-light">
              Custom Solutions
              {' '}
              <br />
              Unmatched Support
            </p>
          </div>
          <div className="w-1/3 mt-0 ml-16 mr-0 sm:ml-0 sm:mr-5">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">
              Office
            </h1>
            <p mailto className="text-lg text-gray-400 font-light">
              <a href="mailto:info@edgydev.com">info@edgydev.com</a>
            </p>
            <p className="text-lg text-gray-400 font-light">
              F7 Islamabad, the capital of Pakistan
            </p>
          </div>
          <div className="w-1/3 ml-16 sm:ml-0 mt-0">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">
              Social
            </h1>
            <Button href="https://www.linkedin.com/company/edgydev/" type="link" target="_blank" className="flex text-lg text-gray-400 font-light hover:underline" isExternal>
              LinkedIn
            </Button>
            <Button href="https://www.instagram.com/edgydev/" type="link" target="_blank" className="flex text-lg text-gray-400 font-light hover:underline" isExternal>
              Instagram
            </Button>
            <Button href="https://www.facebook.com/edgydevx/" type="link" target="_blank" className="flex text-lg text-gray-400 font-light hover:underline" isExternal>
              Facebook
            </Button>
            <Button href="https://www.youtube.com/@edgydevx" type="link" target="_blank" className="flex text-lg text-gray-400 font-light hover:underline" isExternal>
              Youtube
            </Button>
            <Button href="https://x.com/edgydevx" type="link" target="_blank" className="flex text-lg text-gray-400 font-light hover:underline" isExternal>
              X (Twitter)
            </Button>
            <Button href="https://www.threads.net/@edgydev" type="link" target="_blank" className="flex text-lg text-gray-400 font-light hover:underline" isExternal>
              Threads
            </Button>
            <Button href="https://www.tiktok.com/@edgydev" type="link" target="_blank" className="flex text-lg text-gray-400 font-light hover:underline" isExternal>
              TikTok
            </Button>
          </div>
        </div>
        <div className="flex-col text-center mt-7">
          <p className="text-lg text-gray-400 font-light">
            Copyright 2021 - All rights reserved -
            <span className="text-lg text-theme-purple font-light" isExternal>EdgyDev</span>
          </p>
        </div>
      </div>
    </div>
  );
}
